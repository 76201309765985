.container {
  padding-bottom: 16px;
}

.toggleButtonGroup {
  height: 40px;
  width: 100%;
  outline: solid 1px var(--iris-light-focus) !important;
  border-radius: 8px !important;
}

.toggleButtonLeft {
  border-radius: 8px 0 0 8px !important;
  border: none !important;
  width: 100%;
}

.toggleButtonRight {
  border-radius: 0 8px 8px 0 !important;
  border: none !important;
  width: 100%;
}

.toggleButtonSelected {
  background-color: var(--iris-light-focus) !important;
  color: var(--iris) !important;
}
.exchangeDetails {
  padding-top: 16px;
  display: flex;
  align-items: center;
  color: var(--onSurfaceColors-disabled) !important;
  column-gap: 5px;
}

.exchangeDetailsText {
  direction: ltr;
}

.alertMessage {
  background-color: var(--iris-hover) !important;
  margin-top: 8px;
  border-radius: 8px !important;
  color: var(--onSurfaceColors-mediumEmphasis) !important;
  padding: 12px 16px !important;
}
