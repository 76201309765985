.linkDetailsContainer {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding-top: 32px;
  padding-bottom: 32px;
  border-radius: 0px 0px 36px 36px;
  background-color: var(--iris-hover);
}

.descriptionContainer {
  padding-top: 24px;
  padding-bottom: 34px;
}

.extraInfoContainer {
  padding-top: 18px;
  padding-bottom: 20px;
}
