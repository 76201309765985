.yourPaymentContainer {
  padding-top: 16px;
}

.currencyChangeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .changeButtonContainer {
    :hover {
      cursor: pointer;
    }

    .changeButton {
      color: #7d00d4;
      display: flex;
      align-items: center;

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
}
